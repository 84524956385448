<template>
    <article class="c-article c-article--view">
        <ArticleHeader :title="article.title" :date="article.date" :featured_image="article.featured_image" />

        <div v-if="article && article.components" class="utl-clearfix">
            <PartialsPageComponents :components="article.components" />
        </div>

        <div class="c-article__share-wrapper">
            <div class="grid-container">
                <div class="grid-x grid-margin-x align-center">
                    <div class="small-12 medium-12 large-8 cell">
                        <div class="c-article__share s-nuxt-social">
                            <div class="c-article__share-label">Share</div>

                            <GlobalSocialShare :title="article.title" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>

    <template v-if="article.related_articles">
        <ArticleRelated :articles="article.related_articles" />
    </template>
</template>

<script setup>
    const props = defineProps({
        data: {
            type: Object,
            required: false,
        },
    });

    const article = useState("article", () => null);

    if (props.data) {
        article.value = props.data;
    } else {
        const route = useRoute();

        const getArticle = async (slug) => {
            const { data } = await useFetch(`/api/wp/article/${slug}`);

            // Check if data is empty or undefined
            if (!data || !data.value || data.value.length === 0 || data.value.error) {
                // console.log('No data found for the given route:', route.fullPath);

                throw createError({
                    statusCode: 404,
                    statusMessage: "Page Not Found",
                });
            }

            article.value = data;

        };

        // console.log("Route: ", route.params);

        await getArticle(route.params.post.join("/"));
    }

    const getCanonicalURL = () => {
        const requestUrl = useRequestURL();

        if(!article.value) {
            return null;
        }

        if(article.value.seo_canonical) {
            return article.value.seo_canonical;
        }

        if(article.value.custom_permalink) {
            return requestUrl.origin + '/' + article.value.custom_permalink.replace(/^\/|\/$/g, '') + '/';
        }
        
        if(article.value.slug) {
            return requestUrl.origin + '/news/' + article.value.slug.replace(/^\/|\/$/g, '') + '/'
        }

        return null;
    }

    useSeoMeta({
        title: article.value.seo_title ?? article.value.seo_og_title,
        ogTitle: article.value.seo_og_title ?? article.value.seo_title,
        ogDescription: article.value.seo_og_description ?? article.value.seo_description ?? article.value.excerpt,
        description: article.value.seo_description ?? article.value.seo_og_description ?? article.value.excerpt,
        ogUrl: getCanonicalURL(),
        twitterCard: article.value.seo_twitter_card,
        ogSiteName: article.value.seo_og_site_name,
        ogType: article.value.seo_og_type,
        ogLocale: article.value.seo_og_locale,
        ogImage: article.value.seo_og_images && typeof article.value.seo_og_images === 'object' ? Object.values(article.value.seo_og_images)[0] : null,
        robots: article.value.seo_robots ? Object.values(article.value.seo_robots).join(", ") : null,
        articleModifiedTime: article.value.seo_og_article_modified_time,
    })

    if(getCanonicalURL()) {
        useHead({
            link: [
                {
                    rel: "canonical",
                    href: getCanonicalURL()
                }
            ]
        });
    }
</script>
